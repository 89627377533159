import {Injectable} from '@angular/core';
import {FormGroup, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class InputMatchValidator {
  constructor() {
  }

  inputMatchValidator(input1Name: string, input2Name: string): ValidatorFn {
    return (form: FormGroup): { [key: string]: boolean } | null => {

      const input1 = form.controls[input1Name];
      const input2 = form.controls[input2Name];

      // serve a mantenere eventuali altri tipi di errori
      const input2Errors = input2.errors;
      if (input2Errors) {
        delete input2Errors.inputMatch;
        input2.setErrors({
          ...input2Errors
        });
      }

      if (input1 && input1.value.length > 0 && input2 && input2.value.length > 0) {
        const test = input1.value === input2.value;
        if (!test) {
          input2.setErrors((input2Errors) ? {...input2Errors, inputMatch: true} : {inputMatch: true});
          return null;
        }

        // no error
        input2.setErrors((input2Errors) ? {...input2Errors} : null);
        return null;

      }

      return null;
    };
  }

}
