<form [formGroup]="PivaForm" (ngSubmit)="onSubmit()" (keydown.enter)="onSubmit()" novalidate>

  <div class="row justify-content-start">
    <div class="col-12">

      <label for="Piva_Search" class="form-label">
        <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
        Inserisci la tua Partita Iva:</label>
      <div class="input-group has-validation" [ngClass]="{'is-invalid': PivaErrorStatus, 'is-valid': PivaValidStatus}">
        <div class="input-group-text">IT</div>

        <input type="text" class="form-control" formControlName="PartitaIva" id="Piva_Search"
               [appMaxLenghtTyping]="11"
               [appRegexInputTyping]="'[0-9]'"
               [ngClass]="{'is-invalid': PivaErrorStatus, 'is-valid': PivaValidStatus}">

        <button type="submit" class="btn btn-outline" [disabled]="PivaErrorStatus || loading">
          <span *ngIf="!loading">
            <!--<mat-icon fontSet="material-icons-outlined">search</mat-icon>-->
            VERIFICA
          </span>
            <span class="spinner-border spinner-border-sm" role="status" *ngIf="loading">
          </span>
        </button>

        <div class="invalid-feedback" *ngIf="PIvaCtrl.errors?.required">
          Inserire una partita iva
        </div>
        <div class="invalid-feedback" *ngIf="PIvaCtrl.errors?.minlength">
          La partita iva deve essere di {{PIvaCtrl.errors?.minlength?.requiredLength}} caratteri
        </div>
        <div class="invalid-feedback" *ngIf="PIvaCtrl.errors?.pivaInvalid">
          La partita iva inserita non è valida
        </div>

      </div>

    </div>
  </div>
</form>
