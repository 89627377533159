<form class="needs-validation" novalidate [formGroup]="formBilling">

  <div class="row g-3 align-content-end">
    <div class="col-12 fattura-check">
      <mat-slide-toggle id="Fattura" formControlName="Fattura" color="primary"></mat-slide-toggle>
      <label>Hai bisogno della Fattura?</label>
    </div>
  </div>

  <div class="row g-3">

    <div class="col-12">
      <label for="Denominazione"
             class="form-label">{{(!controls.Fattura.value) ? 'Nome e Cognome' : 'Ragione Sociale'}}</label>
      <div class="input-group has-validation">
        <input type="text" class="form-control" id="Denominazione" formControlName="Denominazione"
               [ngClass]="{'is-invalid': controlErrorStatus('Denominazione'), 'is-valid': controlValidStatus('Denominazione')}">
        <div class="invalid-feedback" *ngIf="controls.Denominazione.errors?.required">
          Campo obbligatorio
        </div>
      </div>
    </div>

    <div class="col-12">
      <label for="Indirizzo" class="form-label">Indirizzo</label>
      <div class="input-group has-validation">
        <input type="text" class="form-control" id="Indirizzo" formControlName="Indirizzo"
               [ngClass]="{'is-invalid': controlErrorStatus('Indirizzo'), 'is-valid': controlValidStatus('Indirizzo')}">
        <div class="invalid-feedback" *ngIf="controls.Indirizzo.errors?.required">
          Campo obbligatorio
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <label for="Cap" class="form-label">Cap</label>
      <input type="text" class="form-control" id="Cap" formControlName="Cap"
             [ngClass]="{'is-invalid': controlErrorStatus('Cap'), 'is-valid': controlValidStatus('Cap')}"
             [appMaxLenghtTyping]="5"
             [appRegexInputTyping]="'[0-9]'">
      <div class="invalid-feedback" *ngIf="controls.Cap.errors?.required">
        Campo obbligatorio
      </div>
      <div class="invalid-feedback" *ngIf="controls.Cap.errors?.pattern">
        Formato del Cap non corretto
      </div>
    </div>

    <div class="col-sm-6">
      <label for="Comune" class="form-label">Comune</label>
      <input type="text" class="form-control" id="Comune" formControlName="Comune"
             [ngClass]="{'is-invalid': controlErrorStatus('Comune'), 'is-valid': controlValidStatus('Comune')}">
      <div class="invalid-feedback" *ngIf="controls.Comune.errors?.required">
        Campo obbligatorio
      </div>
    </div>

    <div class="col-sm-3">
      <label for="Provincia" class="form-label">Pr</label>
      <input type="text" class="form-control" id="Provincia" formControlName="Provincia"
             appToUppercase
             [appMaxLenghtTyping]="2"
             [appRegexInputTyping]="'[A-Za-z0-9]'"
             [ngClass]="{'is-invalid': controlErrorStatus('Provincia'), 'is-valid': controlValidStatus('Provincia')}">
      <div class="invalid-feedback" *ngIf="controls.Provincia.errors?.required">
        Campo obbligatorio
      </div>
      <div class="invalid-feedback" *ngIf="controls.Provincia.errors?.pattern">
        Formato Pr non corretto
      </div>
    </div>

    <ng-container *ngIf="controls.Fattura.value">

      <div class="col-12 col-md-6">
        <label for="PartitaIva" class="form-label">Partita Iva</label>
        <div class="input-group has-validation"
             [ngClass]="{'is-invalid': controlErrorStatus('PartitaIva'), 'is-valid': controlValidStatus('PartitaIva')}">
          <span class="input-group-text">IT</span>
          <input type="text" class="form-control" id="PartitaIva" formControlName="PartitaIva"
                 [ngClass]="{'is-invalid': controlErrorStatus('PartitaIva'), 'is-valid': controlValidStatus('PartitaIva')}"
                 [appMaxLenghtTyping]="11"
                 [appRegexInputTyping]="'[0-9]'">
          <div class="invalid-feedback" *ngIf="controls.PartitaIva.errors?.required">
            Campo obbligatorio
          </div>
          <div class="invalid-feedback"
               *ngIf="controls.PartitaIva.errors?.pivaInvalid || controls.PartitaIva.errors?.minlength || controls.PartitaIva.errors?.maxlength">
            La partita iva inserita non è valida
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <label for="CodiceFiscale" class="form-label">Codice Fiscale</label>
        <input type="text" class="form-control" id="CodiceFiscale" formControlName="CodiceFiscale" appToUppercase
               [appMaxLenghtTyping]="16"
               [appRegexInputTyping]="'[A-Za-z0-9]'"
               [ngClass]="{'is-invalid': controlErrorStatus('CodiceFiscale'), 'is-valid': controlValidStatus('CodiceFiscale')}">
        <div class="invalid-feedback" *ngIf="controls.CodiceFiscale.errors?.required">
          Campo obbligatorio
        </div>
        <div class="invalid-feedback" *ngIf="controls.CodiceFiscale.errors?.codiceFiscaleInvalid">
          Codice fiscale non valido
        </div>
      </div>

    </ng-container>

    <div class="col-6">
      <label for="Email" class="form-label">Email </label>
      <input type="email" class="form-control" id="Email" formControlName="Email"
             [ngClass]="{'is-invalid': controlErrorStatus('Email'), 'is-valid': controlValidStatus('Email')}">
      <div class="invalid-feedback" *ngIf="controls.Email.errors?.required">
        Campo obbligatorio
      </div>
      <div class="invalid-feedback" *ngIf="controls.Email.errors?.email">
        Inserire un email valida
      </div>
    </div>

    <div class="col-6">
      <label for="EmailConfirm" class="form-label">Conferma Email</label>
      <!-- Blocco input senza blocco paste in develop -->
      <input type="email" class="form-control" id="EmailConfirm" formControlName="EmailConfirm" *ngIf="isDevelopMode"
             [ngClass]="{'is-invalid': controlErrorStatus('EmailConfirm'), 'is-valid': controlValidStatus('EmailConfirm')}">
      <!-- Blocco input con blocco paste per produzione -->
      <input type="email" class="form-control" id="EmailConfirm" formControlName="EmailConfirm" onpaste="return false;"
             *ngIf="!isDevelopMode"
             [ngClass]="{'is-invalid': controlErrorStatus('EmailConfirm'), 'is-valid': controlValidStatus('EmailConfirm')}">

      <div class="invalid-feedback" *ngIf="controls.EmailConfirm.errors?.required">
        Campo obbligatorio
      </div>
      <div class="invalid-feedback" *ngIf="controls.EmailConfirm.errors?.email">
        Formato email non valido
      </div>
      <div class="invalid-feedback" *ngIf="controls.EmailConfirm.errors?.inputMatch">
        Le due email devono essere uguali
      </div>
    </div>

    <ng-container *ngIf="controls.Fattura.value">
      <div class="col-12 col-md-12">
        <label for="PecCodSdi" class="form-label">Pec o Codice Destinatario</label>
        <input type="text" class="form-control" id="PecCodSdi" formControlName="PecCodSdi"
               [ngClass]="{'is-invalid': controlErrorStatus('PecCodSdi')}">
        <div class="invalid-feedback" *ngIf="controls.PecCodSdi.errors?.required">
          Campo obbligatorio
        </div>
      </div>

      <!--<div class="col-12 col-md-4">
        <label for="CodiceDestinatario" class="form-label">Codice Destinatario <small
          class="text-muted">Facoltativo</small></label>
        <input type="text" class="form-control" id="CodiceDestinatario" formControlName="CodiceDestinatario"
               [ngClass]="{'is-invalid': controlErrorStatus('CodiceDestinatario')}"
               appToUppercase
               [appMaxLenghtTyping]="7"
               [appRegexInputTyping]="'[A-Za-z0-9]'">
        <div class="invalid-feedback" *ngIf="controls.CodiceDestinatario.errors?.pattern">
          Il Codice Destinatario deve essere lungo tra i 6 e i 7 caratteri alfanumerici
        </div>
      </div>-->
    </ng-container>

    <div class="col-12">
      <label for="Telefono" class="form-label">Telefono</label>
      <input type="text" class="form-control" id="Telefono"
             [ngClass]="{'is-invalid': controlErrorStatus('Telefono'), 'is-valid': controlValidStatus('Telefono')}"
             formControlName="Telefono">
      <div class="invalid-feedback" *ngIf="controls.Telefono.errors?.required">
        Campo obbligatorio
      </div>
    </div>

    <div class="col-12">

      <div class="row mb-3">
        <label for="TipologiaPagamento" class="col-sm-3 col-form-label">Metodo di Pagamento</label>
        <div class="col-sm-9">
          <div class="input-group has-validation">
            <select class="form-select " id="TipologiaPagamento" formControlName="TipologiaPagamento"
                    [ngClass]="{'is-invalid': controlErrorStatus('TipologiaPagamento'), 'is-valid': controlValidStatus('TipologiaPagamento')}">
              <option selected value="">Seleziona ...</option>
              <option value="stripe">Carta di Credito</option>
              <!--<option value="paypal">Paypal</option>-->
              <option value="bonifico">Bonifico Bancario</option>
            </select>
            <div class="invalid-feedback" *ngIf="controls.TipologiaPagamento.errors?.required">
              Campo obbligatorio
            </div>
          </div>

          <div class="text-muted pay-info-text" *ngIf="controls.TipologiaPagamento.value">

            <p *ngIf="controls.TipologiaPagamento.value === 'stripe' || controls.TipologiaPagamento.value === 'paypal'">
              Effettua subito il pagamento tramite una normale
              carta di credito (visa, mastercard, postepay ecc...). I pagamenti sono sicuri e controllati.</p>

            <p *ngIf="controls.TipologiaPagamento.value === 'bonifico'">
              Seguendo questa procedura riceverai le coordinate bancarie su cui effettuare il pagamento. Una volta che
              riceveremo l'accredito del bonifico il prodotti verrà spedito.
            </p>
          </div>

        </div>
      </div>
    </div>

    <div class="col-12 fattura-check">
      <mat-slide-toggle id="Spedizione" formControlName="Spedizione" color="primary"></mat-slide-toggle>
      <label>Hai bisogno di spedire ad un altro indirizzo?</label>
    </div>

    <ng-container *ngIf="controls.Spedizione.value">
      <div class="col-12 col-md-12">
        <label for="Nota" class="form-label">Inserisci l'indirizzo a cui spedire</label>
        <textarea rows="4" class="form-control" id="Nota" formControlName="Nota"
                  [ngClass]="{'is-invalid': controlErrorStatus('Nota')}"
                  placeholder="Indirizzo ...&#10;Cap - Comune - Pr&#10;"></textarea>
        <div class="invalid-feedback" *ngIf="controls.Nota.errors?.required">
          Campo obbligatorio
        </div>
      </div>
    </ng-container>

  </div>

  <hr class="my-4">

  <div class="form-check mt-3">
    <input type="checkbox" class="form-check-input" id="check2" formControlName="check2"
           [ngClass]="{'is-invalid': controlErrorStatus('check2'), 'is-valid': controlValidStatus('check2')}">
    <label class="form-check-label" for="check2">Accetto e acconsento alla <a
      href="https://www.oleariagenobile.it/it/privacy" target="_blank">Privacy Policy</a> e <a
      href="https://www.oleariagenobile.it/it/terminiecondizioni" target="_blank">Termini e
      Condizioni</a></label>
    <div class="invalid-feedback" *ngIf="controls.check2.errors?.required">
      E' necessario accettare le condizioni per poter proseguire.
    </div>
  </div>

  <div class="form-check mt-4">
    <input type="checkbox" class="form-check-input" id="check1" formControlName="check1"
           [ngClass]="{'is-invalid': controlErrorStatus('check1'), 'is-valid': controlValidStatus('check1')}">
    <label class="form-check-label" for="check1">Accetto e acconsento al trattamento dei dati personali
      secondo il regolamento GDPR UE 2016/679 e successive modificazioni.</label>
    <div class="invalid-feedback" *ngIf="controls.check1.errors?.required">
      E' necessario accettare le condizioni per poter proseguire.
    </div>
  </div>

</form>
