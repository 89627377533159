import {HttpClient} from '@angular/common/http';

import {ApiAbstract} from './api.abstract';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment as ENV} from '../../../environments/environment';

export interface ListResults {
  totalItems: number;
  totalFiltered: number;
  items: any[];
}

export class MyApiProvider extends ApiAbstract {

  defaultEndpoint: string;

  /*
  E' possibile definire un endpoint di default per la risora che estende la classe
   */
  constructor(httpClient, endpoint?, apiUrl = ENV.apiUrl, apiVersion = ENV.apiVersion) {

    if (!(httpClient instanceof HttpClient)) {
      throw new Error('E necessario instaziare il parametro httpClient per la classe MyApiProvider');
    }

    super(httpClient, apiUrl, apiVersion);

    this.defaultEndpoint = (endpoint) ? endpoint : '';
  }

  get(params: any = {}, endpoint: string = this.defaultEndpoint, options?) {
    return super.get(endpoint, params, options);
  }

  getById(id: number | string, endpoint: string = this.defaultEndpoint, options?) {
    return super.getById(endpoint, id, options);
  }

  getList(params: any, dataTables?, endpoint?): Observable<ListResults> {
    return this.get(params, endpoint, {observe: 'response'}).pipe(
      map((res: any) => this.extractGetListResults(res)),
      catchError(error => throwError(error))
    );
  }

  post(item: any, endpoint: string = this.defaultEndpoint, options?): Observable<any> {
    return super.post(endpoint, item, options);
  }

  put(id: number | string, item: any, endpoint: string = this.defaultEndpoint, options?): Observable<any> {
    return super.put(endpoint, id, item, options);
  }

  patch(id: number | string, item: any, endpoint: string = this.defaultEndpoint, options?): Observable<any> {
    return super.patch(endpoint, id, item, options);
  }

  delete(id: number | string, endpoint: string = this.defaultEndpoint, options?) {
    return super.delete(endpoint, id, options);
  }

  private extractGetListResults(res: any): ListResults {
    const totalItems = Number(res.headers.get('x-tot-items'));
    const totalFiltered = Number(res.headers.get('x-tot-filtered')) || totalItems;

    return {
      totalItems,
      totalFiltered,
      items: res.body
    };
  }

}
