import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StripeCardCvcComponent, StripeCardExpiryComponent, StripeCardNumberComponent, StripeService} from 'ngx-stripe';
import {PaymentIntent, StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {CompletePay} from '../../@types/_interfaces';

export interface StripeError{
  code?: string;
  type?: string;
  message?: string;
  status: boolean;
}

const INITIAL_ERROR = {status: false};

@Component({
  selector: 'app-stripe-payment[orderId][amount]',
  templateUrl: './app-stripe-payment.component.html',
  styleUrls: ['./app-stripe-payment.component.scss']
})
export class AppStripePaymentComponent implements OnInit, AfterViewInit {

  @ViewChild(StripeCardNumberComponent) cardNumber: StripeCardNumberComponent;
  @ViewChild(StripeCardExpiryComponent) expiry: StripeCardExpiryComponent;
  @ViewChild(StripeCardCvcComponent) cvc: StripeCardCvcComponent;

  @Input() orderId: number;
  @Input() amount: number;

  @Output() completePay: EventEmitter<CompletePay> = new EventEmitter<CompletePay>();

  loading = false;

  cardError: StripeError = INITIAL_ERROR;
  expiryError: StripeError = INITIAL_ERROR;
  cvcError: StripeError = INITIAL_ERROR;
  formError = false;
  errorMessage: string;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        color: '#212529',
        padding: '6px 12px',
        fontWeight: '400',
        fontSize: '16px',
        '::placeholder': {
          // color: '#92949c',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'it',
  };

  stripeForm: FormGroup;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private stripeService: StripeService
  ) {
  }

  ngOnInit(): void {
    this.stripeForm = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  refreshFormError(){
    this.formError = (this.cardError.status || this.expiryError.status || this.cvcError.status);
  }

  ngAfterViewInit() {

    this.cardNumber.change.pipe(
      map(({error, empty}) => ({
        ...error,
        status: !!error?.code || empty
      })),
      // tap((err) => console.log('card', err)),
    ).subscribe((error) => {
      this.cardError = error;
      this.refreshFormError();
    });

    this.expiry.change.pipe(
      map(({error, empty}) => ({
        ...error,
        status: !!error?.code || empty
      })),
      // tap((err) => console.log('expiry', err)),
    ).subscribe((error) => {
      this.expiryError = error;
      this.refreshFormError();
    });

    this.cvc.change.pipe(
      map(({error, empty}) => ({
        ...error,
        status: !!error?.code || empty
      })),
      // tap((err) => console.log('cvc', err)),
    ).subscribe((error) => {
      this.cvcError = error;
      this.refreshFormError();
    });

  }

  pay(): void {

    if (!this.stripeForm.valid || this.formError) {
      this.loading = false;
      this.errorMessage = 'Compilare correttamente tutti i campi!';
      return;
    }

    this.loading = true;
    this.errorMessage = '';
    this.createPaymentIntent()
      .pipe(
        switchMap((pi: PaymentIntent) =>
          this.stripeService.confirmCardPayment(pi.client_secret, {
            payment_method: {
              card: this.cardNumber.element,
              billing_details: {
                name: this.stripeForm.get('name').value,
              },
            },
          })
        ),
        /*catchError((error) => {
          this.errorMessage = error.error.message;
          this.loading = false;
          return of(error);
        })*/
      )
      .subscribe((result) => {
        if (result?.error) {
          // Show error to your customer (e.g., insufficient funds)
          this.errorMessage = result.error.message;
        } else {
          // The payment has been processed!
          this.errorMessage = '';
          if (result.paymentIntent.status === 'succeeded') {
            this.completePay.emit({
              tipo: 'stripe',
              orderId: this.orderId,
              extra: {paymentId: result.paymentIntent.id}
            });
          }
        }
      }, error => {
        this.errorMessage = error.error.message;
        this.loading = false;
      }, () => {
        this.loading = false;
      });

  }

  createPaymentIntent(): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `${environment.stripe_intent_url}`,
      {id: this.orderId}
    );
  }

}
