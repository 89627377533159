import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {CartData, Ordine} from '../../../../core/@types';
import {CompletePay} from '../../../../shared/@types/_interfaces';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-payment[cartData][ordine]',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements AfterViewInit {

  @Input() cartData: CartData;
  @Input() ordine: Ordine;

  @Output() completePay: EventEmitter<CompletePay> = new EventEmitter<CompletePay>();

  stepCompleted = false;
  stepError = false;
  loading = true;
  printOrderUrl = environment.printOrderUrl;

  constructor() {
  }

  ngAfterViewInit() {
    if(this.ordine.TipologiaPagamento === 'bonifico'){
      parent.postMessage('reset_cart', environment.iframeParentUrl);
    }
  }

  paymentComplete(data: CompletePay) {
    this.completePay.emit(data);
    this.stepCompleted = true;
    parent.postMessage('reset_cart', environment.iframeParentUrl);
  }

  paymentError($event: any) {
    this.stepCompleted = false;
    this.stepError = true;
  }
}
