import {Injectable} from '@angular/core';
import {MyApiProvider} from '../../shared/abstract/my.api';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Ordine} from '../@types';
import {BehaviorSubject, Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CompletePay} from '../../shared/@types/_interfaces';

@Injectable({
  providedIn: 'root',
})
export class OrdiniService extends MyApiProvider {

  private ordine$: BehaviorSubject<Ordine> = new BehaviorSubject<Ordine>(null);

  constructor(http: HttpClient, private snackBar: MatSnackBar) {
    super(http, '/ordini');
  }

  public getOrdine(): Ordine {
    return this.ordine$.value;
  }

  public getOrdineAsObservable$(): Observable<Ordine> {
    return this.ordine$.asObservable();
  }

  public setOrdine(ordine: Ordine) {
    this.ordine$.next(ordine);
  }

  sendOrdine(ordine: Ordine): Observable<Ordine> | null {
    return this.post(ordine).pipe(
      map((data: Ordine) => {
        this.setOrdine(data);
        return data;
      }),
      catchError(error => {
        const message = error?.error?.message ?? 'Errore durante l\'invio dell\'ordine';
        this.snackBar.open(message, 'OK', {
          duration: 3000,
          panelClass: 'danger-snackbar'
        });
        throw error;
      })
    );
  }

  setOrdinePagato(payData: CompletePay): Observable<Ordine> | null {
    return this.post({orderId: payData.orderId, tipo: payData.tipo, paymentId: payData.extra.paymentId}, '/payment-complete').pipe(
      map((data: Ordine) => {
        this.ordine$.next(data);
        return data;
      }),
      catchError(error => {
        this.snackBar.open('Errore durante l\'aggiornamento dell\'ordine', 'OK', {
          duration: 3000,
          panelClass: 'danger-snackbar'
        });
        throw error;
      })
    );
  }

}
