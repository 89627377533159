import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ShopComponent} from './views/shop/shop.component';
import {HomeComponent} from './views/home/home.component';
import {CartComponent} from './views/shop/components/cart/cart.component';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CdkStepper} from '@angular/cdk/stepper';
import {SharedModule} from './shared/shared.module';
import {CoreModule} from './core/core.module';
import {ShopViewResolver} from './views/resolvers/shop.resolver';
import {BillingFormComponent} from './views/shop/components/billing-form/billing-form.component';
import {PivaGetDataFormComponent} from './views/shop/components/piva-get-data-form/piva-get-data-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APiBaseUrlInterceptor} from './core/interceptors/api-base-url.interceptors';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {A11yModule} from '@angular/cdk/a11y';
import {NgxStripeModule} from 'ngx-stripe';
import {environment} from '../environments/environment';
import { PaymentComponent } from './views/shop/components/payment/payment.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    HomeComponent,
    CartComponent,
    BillingFormComponent,
    PivaGetDataFormComponent,
    PaymentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    CoreModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatStepperModule,
    MatTooltipModule,
    MatSnackBarModule,
    A11yModule,
    NgxStripeModule.forRoot(environment.stripe_public_key),
    MatProgressSpinnerModule,
    MatSlideToggleModule,
  ],
  providers: [
    CdkStepper,
    ShopViewResolver,
    {provide: HTTP_INTERCEPTORS, useClass: APiBaseUrlInterceptor, multi: true},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {verticalPosition: 'top', horizontalPosition: 'center'}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
