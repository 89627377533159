import {Component, EventEmitter, Input, Output} from '@angular/core';

import {environment} from '../../../../environments/environment';
import {IClientAuthorizeCallbackData, ICreateOrderRequest, IPayPalConfig} from 'ngx-paypal';
import {CompletePay} from '../../@types/_interfaces';

@Component({
  selector: 'app-paypal-payment',
  templateUrl: './app-paypal-payment.component.html',
})
export class AppPaypalPaymentComponent {

  loading = true;

  @Input() orderId: number;
  @Input() amount: number;

  @Output() completePay: EventEmitter<CompletePay> = new EventEmitter<CompletePay>();
  @Output() errorPay: EventEmitter<any> = new EventEmitter<any>();

  public payPalConfig ?: IPayPalConfig;

  constructor() {
    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: environment.paypalCurrency,
      clientId: (environment.production) ? environment.payPalProductionId : environment.payPalSandboxId,
      createOrderOnClient: (data) => <ICreateOrderRequest> {
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: environment.paypalCurrency,
              value: this.amount.toString(),
              breakdown: {
                item_total: {
                  currency_code: environment.paypalCurrency,
                  value: this.amount.toString()
                }
              }
            },
            items: [
              {
                name: 'Ordine #' + this.orderId,
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: environment.paypalCurrency,
                  value: this.amount.toString(),
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true',
        extraQueryParams: [ { name: 'disable-funding', value: 'sofort,mybank'} ]
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onInit: () => {
        this.loading = false;
      },
      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data: IClientAuthorizeCallbackData) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point');
        this.paypalSuccess(data);
      },
      onCancel: (data, actions) => {
        this.onCancel(data);
      },
      onError: (err) => {
        this.paypalError(err);
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
        // this.resetStatus();
      },
    };
  }

  paypalSuccess(data: IClientAuthorizeCallbackData) {
    if (data && data.status === 'COMPLETED') {
      this.completePay.emit({
        tipo: 'paypal',
        orderId: this.orderId,
        extra: {
          paymentId: data.id
        }
      });
      return;
    }else{
      /*console.log('paypal error');
      console.log(data);*/
      this.errorPay.emit();
    }
  }

  paypalError(err) {
    /*console.log('paypal error');
    console.log(err);*/
    this.errorPay.emit();
  }

  onCancel(data) {
    // console.log('cancel', data);
  }

}
