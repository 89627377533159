import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CodiceFiscaleValidator} from '../../../../shared/validators/codice-fiscale-validator.service';
import {InputMatchValidator} from '../../../../shared/validators/input-match-validator.service';
import {Unsubscriber} from '../../../../shared/@types';
import {takeUntil} from 'rxjs/operators';
import {PartitaIvaValidator} from '../../../../shared/validators/partita-iva-validator.service';
import {environment} from '../../../../../environments/environment';
import {CartData} from '../../../../core/@types';

@Component({
  selector: 'app-billing-form[cartData]',
  templateUrl: './billing-form.component.html',
  styleUrls: ['./billing-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingFormComponent extends Unsubscriber implements OnInit, AfterViewInit {

  // tslint:disable-next-line:variable-name
  public _cartData: CartData;

  @Input() formMode: 'Azienda' | 'PersonaFisica' = 'PersonaFisica';

  @Input() set cartData(value) {
    this._cartData = value;
    this.formBilling.patchValue(value);
  }

  get controls() {
    return this.formBilling.controls;
  }

  constructor(private fb: FormBuilder,
              private changeDetectionRef: ChangeDetectorRef,
              private inputMatchValidator: InputMatchValidator,
              private pivaValidator: PartitaIvaValidator,
              private codiceFiscaleValidator: CodiceFiscaleValidator) {
    super();
  }

  @Output() formInvalid: EventEmitter<boolean> = new EventEmitter<boolean>();

  public formBilling: FormGroup = this.fb.group({
    Fattura: [false, [Validators.required]],
    Denominazione: ['', [Validators.required]],
    Indirizzo: ['', [Validators.required]],
    Cap: ['', [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
    Comune: ['', [Validators.required]],
    Provincia: ['', [Validators.required, Validators.pattern(/^[A-Za-z]{2}$/)]],
    PartitaIva: ['', [Validators.minLength(11), Validators.maxLength(11), this.pivaValidator.partitaIvaValidator()]],
    CodiceFiscale: ['', [this.codiceFiscaleValidator.codiceFiscaleValidator()]],
    Email: ['', [Validators.required, Validators.email]],
    EmailConfirm: ['', [Validators.required, Validators.email]],
    PecCodSdi: [''],
    // CodiceDestinatario: ['', [Validators.pattern(/^[A-Za-z0-9]{6,7}$/)]],
    Telefono: ['', [Validators.required]],
    TipologiaPagamento: ['', [Validators.required]],
    check1: ['', [Validators.requiredTrue]],
    check2: ['', [Validators.requiredTrue]],
    Spedizione: [false, [Validators.required]],
    Nota: ['']
  }, {
    validators: [this.inputMatchValidator.inputMatchValidator('Email', 'EmailConfirm')]
  });

  isDevelopMode: boolean = !environment.production;

  controlErrorStatus(controlName): boolean {
    const ctrl = this.controls[controlName];
    return ctrl && ctrl.invalid && ctrl.touched;
  }

  controlValidStatus(controlName): boolean {
    const ctrl = this.controls[controlName];
    return ctrl && ctrl.valid && ctrl.touched;
  }

  ngOnInit() {

    this.onChangeRichiediFattura(this._cartData.Fattura);

    this.formBilling.get('Fattura').valueChanges.pipe(
      takeUntil(this.unsubscriber$)
    ).subscribe((Fattura) => {
      // console.log(Fattura);
      this.onChangeRichiediFattura(Fattura);
    });

  }

  ngAfterViewInit() {
    this.formBilling.statusChanges.pipe(
      takeUntil(this.unsubscriber$)
    ).subscribe((status) => {
      this.formInvalid.emit(status === 'VALID');
    });
  }

  public markAllControlsTouched() {
    this.formBilling.markAllAsTouched();
    this.formBilling.updateValueAndValidity();
    this.changeDetectionRef.detectChanges();
  }

  public disableCompletedForm() {
    this.formBilling.disable({emitEvent: false});
    this.formBilling.updateValueAndValidity();
    this.changeDetectionRef.detectChanges();
  }

  private onChangeRichiediFattura(Fattura: boolean){
    if (Fattura) {
      /*this.formBilling.controls.Indirizzo.setValidators([Validators.required]);
      this.formBilling.controls.Cap.setValidators([Validators.required, Validators.pattern(/^[0-9]{5}$/)]);
      this.formBilling.controls.Comune.setValidators([Validators.required]);
      this.formBilling.controls.Provincia.setValidators([Validators.required, Validators.pattern(/^[A-Za-z]{2}$/)]);*/

      this.formBilling.controls.PecCodSdi.setValidators([Validators.required]);

      if (this.formMode === 'PersonaFisica') {
        // this.formBilling.controls.CodiceFiscale.setValidators([Validators.required, this.codiceFiscaleValidator.codiceFiscaleValidator()]);
        this.formBilling.controls.PartitaIva.setValidators(
          [Validators.required, Validators.minLength(11), Validators.maxLength(11), this.pivaValidator.partitaIvaValidator()]);
        // this.formBilling.controls.PartitaIva.clearValidators();
        // this.formBilling.controls.PartitaIva.disable();
      }else{
        this.formBilling.controls.PartitaIva.setValidators(
          [Validators.required, Validators.minLength(11), Validators.maxLength(11), this.pivaValidator.partitaIvaValidator()]);
        this.formBilling.controls.PartitaIva.enable();
      }
    } else {
      /*this.formBilling.controls.Indirizzo.clearValidators();
      this.formBilling.controls.Indirizzo.reset();
      this.formBilling.controls.Cap.setValidators([Validators.pattern(/^[0-9]{5}$/)]);
      this.formBilling.controls.Cap.reset();
      this.formBilling.controls.Comune.clearValidators();
      this.formBilling.controls.Comune.reset();
      this.formBilling.controls.Provincia.setValidators([Validators.pattern(/^[A-Za-z]{2}$/)]);
      this.formBilling.controls.Provincia.reset();
      this.formBilling.controls.CodiceFiscale.setValidators([this.codiceFiscaleValidator.codiceFiscaleValidator()]);
      this.formBilling.controls.CodiceFiscale.reset();*/
      this.formBilling.controls.PartitaIva.clearValidators();
      this.formBilling.controls.PartitaIva.reset();
      // this.formBilling.controls.PartitaIva.disable();
      this.formBilling.controls.PecCodSdi.clearValidators();
    }

    /*this.formBilling.controls.Indirizzo.updateValueAndValidity();
    this.formBilling.controls.Cap.updateValueAndValidity();
    this.formBilling.controls.Comune.updateValueAndValidity();
    this.formBilling.controls.Provincia.updateValueAndValidity();*/
    this.formBilling.controls.CodiceFiscale.updateValueAndValidity();
    this.formBilling.controls.PartitaIva.updateValueAndValidity();
    this.formBilling.controls.PecCodSdi.updateValueAndValidity();

    this.formBilling.updateValueAndValidity();
    this.changeDetectionRef.detectChanges();
  }

}
