import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CodiceFiscaleValidator {
  constructor() {
  }

  codiceFiscaleValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (!control.value) {
        return null;
      }

      const valid = this.controllaCodiceFiscale(control.value);

      return (valid) ? {codiceFiscaleInvalid: valid} : null;
    };
  }

  // return true se non valido false se valido
  private controllaCodiceFiscale(value: string) {

    let s;

    const validi = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const setpari = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const setdisp = 'BAKPLCQDREVOSFTGUHMINJWZYX';

    // Non controllo il caso della partita iva
    if (value.length === 11) { return null; }

    value = value.toUpperCase();
    if (value.length !== 11 && value.length !== 16) {
      return true;
    }

    /*return "La lunghezza del codice fiscale non è\n"
     + "corretta: il codice fiscale dovrebbe essere lungo\n"
     + "esattamente 16 caratteri.\n";*/
    for (let i = 0; i < 16; i++) {
      if (validi.indexOf(value.charAt(i)) === -1) {
        return true;
      }
    }

    s = 0;
    for (let i = 1; i <= 13; i += 2) {
      s += setpari.indexOf(set2.charAt(set1.indexOf(value.charAt(i))));
    }

    for (let i = 0; i <= 14; i += 2) {
      s += setdisp.indexOf(set2.charAt(set1.indexOf(value.charAt(i))));
    }

    if (s % 26 !== value.charCodeAt(15) - 'A'.charCodeAt(0)) {
      return true;
    }

    return false;
  }
}
