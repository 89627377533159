<div class="row g-5 py-3 justify-content-center">

  <div class="col-lg-10 col-12">

    <mat-horizontal-stepper linear disableRipple>

      <mat-step state="cart" label="Carrello"
                [completed]="step1Completed">

        <div class="row justify-content-center">
          <div class="col-md-12 cart-block">

            <app-cart [cartData]="cartData$ | async"></app-cart>

            <!-- Footer -->
            <div class="row justify-content-between align-items-end">

              <div [ngClass]="{'col-4':pivaCheck, 'col-12': !pivaCheck}">
                <button class="btn btn-primary float-end btn-lg"
                        (click)="completeStep1()"
                        [matTooltipDisabled]="!!pivaFormStatus"
                        matTooltip="Inserire la partita iva per proseguire!"
                        matTooltipClass="tooltip-warning"
                        matTooltipPosition="before">Avanti
                  <mat-icon fontSet="material-icons-outlined">navigate_next</mat-icon>
                </button>
              </div>
            </div>

          </div>
        </div>
      </mat-step>

      <mat-step state="person" label="Intestazione"
                [completed]="step2Completed">

        <div class="row justify-content-center">
          <div class="col-md-12 cart-block">

            <app-billing-form [formMode]="formMode"
              [cartData]="cartData$ | async" (formInvalid)="formInvalidChange($event)"></app-billing-form>

            <!-- Footer -->
            <div class="d-flex flex-row justify-content-end">

              <button class="btn btn-primary btn-lg send-ordine" [disabled]="loading"
                      [ngClass]="{'btn-success':formIsValid && !showErrorTooltip, 'btn-danger':!formIsValid && showErrorTooltip}"
                      matTooltip="Compila correttamente tutti i campi"
                      matTooltipClass="tooltip-danger"
                      matTooltipPosition="above"
                      [matTooltipDisabled]="!showErrorTooltip || step2Completed"
                      (click)="submitBillingForm()">
                <span *ngIf="!loading">Avanti <mat-icon
                  fontSet="material-icons-outlined">navigate_next</mat-icon></span>
                <span class="spinner-border text-light spinner-border-sm" role="status" *ngIf="loading"><span
                  class="visually-hidden">Invio ...</span></span>
              </button>
            </div>

          </div>
        </div>

      </mat-step>

      <mat-step state="pay" label="Pagamento" [editable]="false">

        <div class="row justify-content-center">
          <div class="col-md-12 cart-block">

            <app-payment *ngIf="step2Completed" [cartData]="cartData$ | async" [ordine]="ordine$ | async"
            (completePay)="paymentCompleted($event)"></app-payment>

            <!-- Footer -->
            <!--<div class="d-flex flex-row justify-content-end">
              <button class="btn btn-primary btn-lg">Completa Pagamento
                <mat-icon fontSet="material-icons-outlined">navigate_next</mat-icon>
              </button>
            </div>-->

          </div>
        </div>

      </mat-step>

      <!-- Icons template -->

      <ng-template matStepperIcon="cart">
        <mat-icon>shopping_cart</mat-icon>
      </ng-template>

      <ng-template matStepperIcon="person">
        <mat-icon>person</mat-icon>
      </ng-template>

      <ng-template matStepperIcon="pay">
        <mat-icon>payments</mat-icon>
      </ng-template>

    </mat-horizontal-stepper>

  </div>

</div>
