// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: '1.0.2',
  production: false,
  iva: 4,
  apiUrl: 'https://www.oleariagenobile.it',
  apiVersion: 'v1',
  apiSecretKey: '602972981dd037c3d594c012ae3785a1',
  devModeHeaderPw: 'JackDev2590',
  iframeParentUrl: 'https://www.oleariagenobile.it',
  printOrderUrl: 'https://www.oleariagenobile.it/printorder/',
  prodottiImageBaseUrl: 'https://www.oleariagenobile.it/public/prodotti/',
  // stripe_public_key: 'pk_live_51KHaUUHcWD2CIHXgmrfJie90qqQG6PqroLlzh0RQIyIAThsdj04FdO06AZAV8DV4Drm2iIqGsXBYiCvc04SGHERu00bZ212uOz',
  stripe_public_key: 'pk_test_b6dH9bnA5HZblTpMf8dszYq500qWr1kbGj',
  stripe_intent_url: 'https://www.oleariagenobile.it/v1/payment-intent',
  prezzi_ivati: true,

  payPalEnv: 'PayPalEnvironmentSandbox',
  payPalProductionId: '',
  payPalSandboxId: '',
  payPalShopName: '',
  payPalMerchantPrivacyPolicyURL: 'https://www.oleariagenobile.it/privacy-policy',
  payPalMerchantUserAgreementURL: 'https://www.oleariagenobile.it/terminiecondizioni',
  paypalCurrency: 'EUR'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
