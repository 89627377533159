import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {CartData} from '../@types';
import {HttpClient} from '@angular/common/http';
import {MyApiProvider} from '../../shared/abstract/my.api';
import {filter, map} from 'rxjs/operators';
import {Router} from '@angular/router';

const INITIAL_CART_DATA = {id: -1, Prodotti: [], IvaInclusa: environment.prezzi_ivati};

@Injectable({
  providedIn: 'root',
})
export class CartService extends MyApiProvider {

  cart$: BehaviorSubject<CartData> = new BehaviorSubject<CartData>(INITIAL_CART_DATA);

  constructor(http: HttpClient, private router: Router) {
    super(http, '/cart');
  }

  public getCartData(): CartData {
    return this.cart$.value;
  }

  public getCartDataObservable$(): Observable<CartData> {
    return this.cart$.asObservable();
  }

  public setCartData(params: Partial<CartData>) {
    this._refreshCartTotal(params);
  }

  public loadCartData$(ccode): Observable<CartData> {
    return this.get({ccode}, this.defaultEndpoint, {observe: 'body'}).pipe(
      map((res) => res[0]),
      filter((data) => {
        if (!data.IsOrdine){
          return true;
        } else {
          this.router.navigateByUrl('home');
          return false;
        }
      }),
      map((data) => {
        const cartData = {
          id: data.id,
          Prodotti: data.Prodotti ?? []
        };
        this._refreshCartTotal(cartData);
        return cartData;
      })
    );
  }

  private _refreshCartTotal(params?: Partial<CartData>) {

    let cartData = this.cart$.value;

    if (params) {
      cartData = {
        ...cartData,
        ...params
      };
    }

    // Calcolo del totale del carrello con gli articoli
    const Totali = cartData.Prodotti.reduce((acc, rigaCarrello) => {
      if (!cartData.IvaInclusa) {
        const Imponibile = (Number(rigaCarrello.Prezzo) * rigaCarrello.Quantita) + acc.Imponibile;
        const Imposta = Number((Imponibile / 100) * environment.iva) + acc.Imposta;
        return {Imponibile, Imposta, Totale: Imponibile + Imposta};
      } else {
        const Totale = (Number(rigaCarrello.Prezzo) * rigaCarrello.Quantita) + acc.Totale;
        const Imposta = Number((Totale / 100) * environment.iva);
        return {Totale, Imposta, Imponibile: Totale - Imposta};
      }
    }, {Imponibile: 0, Imposta: 0, Totale: 0});

    this.cart$.next({
      ...cartData,
      Iva: environment.iva,
      ...Totali
    });

  }

}
