<div class="row">

  <div class="col-md-6">
    <h5><mat-icon>description</mat-icon> Riepilogo ordine</h5>
    <app-cart [cartData]="cartData" [ordine]="ordine" [showRiepilogo]="true"></app-cart>
  </div>

  <div class="col-md-6">

    <ng-template [ngTemplateOutlet]="eletronicPay"
                 *ngIf="!stepCompleted && !stepError"></ng-template>

    <ng-template [ngTemplateOutlet]="bonificoPay"
                 *ngIf="!stepCompleted && !stepError && ordine.TipologiaPagamento === 'bonifico'"></ng-template>

    <ng-template [ngTemplateOutlet]="paymentCompleted"
                 *ngIf="stepCompleted"></ng-template>

    <ng-template [ngTemplateOutlet]="paymentErrorBlock"
                 *ngIf="stepError"></ng-template>
  </div>

</div>

<ng-template #eletronicPay>

  <h5><mat-icon>credit_card</mat-icon> Pagamento</h5>

  <ng-container>

    <!--<app-paypal-payment
      *ngIf="ordine.TipologiaPagamento === 'paypal'"
      [amount]="ordine.Totale" [orderId]="ordine.id"
      (completePay)="paymentComplete($event)"
      (errorPay)="paymentError($event)"></app-paypal-payment>-->

    <app-stripe-payment
      *ngIf="ordine.TipologiaPagamento === 'stripe'" (completePay)="paymentComplete($event)"
      [orderId]="ordine.id" [amount]="ordine.Totale"></app-stripe-payment>

  </ng-container>

</ng-template>

<ng-template #bonificoPay>
  <div>

    <h5>Ordine d'acquisto</h5>

    <div class="alert alert-success mb-2">
      <p>Per completare l'acquisto inviare tramite email l'ordine e la ricevuta del bonifico effettuato</p>

      <strong>(Nell'ordine sono riportate tutte le informazioni necessarie per completare la procedura)</strong>
      <br>
    </div>

    <br>

    <a [href]="printOrderUrl + ordine.printcode" target="_blank">
      <button class="btn btn-primary w-100">
        <mat-icon>print</mat-icon>
        Stampa Ordine
      </button>
    </a>

    <br>
  </div>
</ng-template>

<ng-template #paymentCompleted>
  <div class="alert alert-success text-center complete-payment-block">
    <h4>Pagamento completato</h4>
    <img class="img-fluid center-block" src="./assets/img/icon-success-outline.svg"  alt=""/>
    <p>Il pagamento è andato a buon fine riceverai entro 24 ore una email con la conferma.</p>
  </div>
</ng-template>

<ng-template #paymentErrorBlock>
  <div class="alert alert-danger text-center complete-payment-block">
    <h4>Si è verificato un errore!</h4>
    <img class="img-fluid center-block" src="./assets/img/icon-success-outline.svg"  alt=""/>
    <p>Il pagamento non è andato a buon fine in quanto sono stati rilevati degli errori. L'ordine è stato annullato e non ti è stato addebitato nessun importo. Effettuare un nuovo ordine per l'acquisto.</p>
  </div>
</ng-template>

<ng-template #loadingBlock>
  <div class="row justify-content-center">
    <div class="cell">
      <mat-spinner [diameter]="42"></mat-spinner>
    </div>
  </div>
</ng-template>
