import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxStripeModule} from 'ngx-stripe';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';

import * as Directives from './directives';
import * as Components from './components';
import {NgxPayPalModule} from 'ngx-paypal';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    Directives.AppMaxLenghtTypingDirective,
    Directives.AppRegexInputTypingDirective,
    Directives.AppToUppercaseDirective,
    Components.AppStripePaymentComponent,
    Components.AppPaypalPaymentComponent
  ],
    imports: [
        CommonModule,
        NgxStripeModule,
        ReactiveFormsModule,
        MatIconModule,
        NgxPayPalModule,
        MatProgressSpinnerModule
    ],
  exports: [
    Directives.AppMaxLenghtTypingDirective,
    Directives.AppRegexInputTypingDirective,
    Directives.AppToUppercaseDirective,
    Components.AppStripePaymentComponent,
    Components.AppPaypalPaymentComponent
  ]
})
export class SharedModule {
}
