<ngx-stripe-card-group
  [formGroup]="stripeForm"
  [elementsOptions]="elementsOptions">

  <div class="mt-2">

    <div class="alert alert-danger mt-2 alert-sm" *ngIf="errorMessage">
      {{errorMessage}}
    </div>

    <div class="row gy-3">
      <div class="col-md-12">
        <input class="form-control" formControlName="name" placeholder="Intestatario carta"/>
        <div class="stripe-error" *ngIf="stripeForm.controls.name.touched && stripeForm.controls.name?.errors?.required">
          Inserire l'intestatario della carta
        </div>
      </div>

      <div class="col-md-12">
        <ngx-stripe-card-number class="form-control" [options]="cardOptions"></ngx-stripe-card-number>
        <div class="stripe-error" *ngIf="cardError.status && cardError.message">{{cardError.message}}</div>
      </div>

      <div class="col-md-8">
        <ngx-stripe-card-expiry class="form-control" [options]="cardOptions"></ngx-stripe-card-expiry>
        <div class="stripe-error" *ngIf="expiryError.status && expiryError.message">{{expiryError.message}}</div>
      </div>

      <div class="col-md-4">
        <ngx-stripe-card-cvc class="form-control" [options]="cardOptions"></ngx-stripe-card-cvc>
        <div class="stripe-error" *ngIf="cvcError.status && cvcError.message">{{cvcError.message}}</div>
      </div>

      <div class="col-12">
        <button class="btn w-100 btn-success" (click)="pay()"
                [disabled]="loading">
          <ng-container *ngIf="!loading">
            Avvia Pagamento
            <mat-icon fontSet="material-icons-outlined">navigate_next</mat-icon>
          </ng-container>
          <span class="spinner-border text-light spinner-border-sm" role="status" *ngIf="loading">
            <span class="visually-hidden">Loading...</span>
          </span>
        </button>
      </div>

    </div>

    <div class="d-flex justify-content-end mt-3">
      <img width="125" alt="Stripe" src="./assets/img/stripe-secure-payments.png" />
    </div>

  </div>

</ngx-stripe-card-group>


