import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {environment} from '../../../environments/environment';

const PUBLIC_RESOURCES = ['index'];

@Injectable()
export class APiBaseUrlInterceptor implements HttpInterceptor {

  /*baseHeaders: any = (environment.production) ? {
    'Content-Type': 'application/json; charset=utf-8'
  } : {
    'Content-Type': 'application/json; charset=utf-8',
    // 'X-As-Dev-Pw': environment.devModeHeaderPw,
    // 'X-As-Dev-On': '1',
  };*/

  baseHeaders: any = {
    'Content-Type': 'application/json; charset=utf-8'
  };

  // l'interceptor funziona solo per il valore apiUrl principale
  urlCheckRegex = new RegExp(`${environment.apiUrl.replace('https://', '')}/${environment.apiVersion}`);
  resourceUrlCheckRegex = new RegExp(`${PUBLIC_RESOURCES.join('|')}`);

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    const headers = (this.urlCheckRegex.test(req.url) && !this.resourceUrlCheckRegex.test(req.url)) ? {
      ...this.baseHeaders,
      Authorization: `Secret ${environment.apiSecretKey}`
    } : this.baseHeaders;

    return next.handle(req.clone({
      setHeaders: headers,
    }));
  }

}
