import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'input[appRegexInputTyping]',
})
export class AppRegexInputTypingDirective {

  @Input() appRegexInputTyping: string;

  constructor() {
  }

  @HostListener('keypress', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    const regex = new RegExp(this.appRegexInputTyping);
    const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }

}
