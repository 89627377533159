<ul class="list-group mb-3 {{class}}">

  <li class="list-group-item lh-sm" *ngFor="let prodotto of cartData.Prodotti">
    <div class="d-flex">
      <div class="flex-shrink-0">
        <img class="img-fluid img-thumbnail" style="max-width: 80px;" src="{{baseImagePath+prodotto.ImgProdotto}}" alt="{{prodotto.NomeProdotto}}">
      </div>
      <div class="flex-grow-1 ms-3">
        <h6 class="my-0">{{prodotto.Quantita}} x {{prodotto.NomeProdotto}}</h6>
      </div>
      <div class="flex-shirk-1 ms-3">
        <span class="text-muted">{{(prodotto.Prezzo * prodotto.Quantita) | currency:'€'}}</span>
      </div>
    </div>
  </li>

  <li class="list-group-item d-flex justify-content-between lh-sm">
    <div>
      <h6 class="my-0">Iva ({{cartData.Iva}}%)</h6>
    </div>
    <span class="text-muted">{{ cartData.Imposta | currency:'€ '}}</span>
  </li>

  <li class="list-group-item d-flex justify-content-between">
    <h6><b>Totale</b></h6>
    <strong>{{cartData.Totale | currency:'€'}}</strong>
  </li>
</ul>

<div class="mt-4 riepilogo-block" *ngIf="showRiepilogo && ordine">

  <dl class="row">
    <dt class="col-sm-5">Denominazione:</dt>
    <dd class="col-sm-7">{{ordine.Denominazione}}</dd>

    <ng-container *ngIf="ordine.Fattura">
      <dt class="col-sm-5">Indirizzo:</dt>
      <dd class="col-sm-7">
        <p>{{ordine.Indirizzo}}</p>
        <p>{{ordine.Cap}} - {{ordine.Comune}} ({{ordine.Provincia}})</p>
      </dd>

      <dt class="col-sm-5">Partita Iva:</dt>
      <dd class="col-sm-7">{{ordine.PartitaIva}}</dd>

      <dt class="col-sm-5 text-truncate">Codice Fiscale:</dt>
      <dd class="col-sm-7">{{ordine.CodiceFiscale}}</dd>

    </ng-container>

    <dt class="col-sm-5 text-truncate">Email:</dt>
    <dd class="col-sm-7">{{ordine.Email}}</dd>

  </dl>

</div>
