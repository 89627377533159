import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PartitaIvaValidator {
  constructor() {
  }

  partitaIvaValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (!control.value || control.value.length !== 11) {
        return null;
      }

      const valid = this.controllaPartitaIva(control.value);

      return (valid) ? {pivaInvalid: valid} : null;
    };
  }

  // return true se non valido false se valido
  private controllaPartitaIva(value: string) {

    // Elimino gli eventuali IT o caratteri non ammessi per il controllo
    value = value.replace(/[^0-9]+/g, '');

    let s = 0;
    for (let i = 0; i <= 9; i += 2) {
      s += value.charCodeAt(i) - '0'.charCodeAt(0);
    }

    for (let i = 1; i <= 9; i += 2) {
      let c = 2 * (value.charCodeAt(i) - '0'.charCodeAt(0));
      c = (c > 9) ? c - 9 : c;
      s += c;
    }

    const atteso = (10 - s % 10) % 10;
    if (atteso !== value.charCodeAt(10) - '0'.charCodeAt(0)) {
      return true;
    }

    return false;
  }
}
