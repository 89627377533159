import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PartitaIvaValidator} from '../../../../shared/validators/partita-iva-validator.service';
import {CartData} from '../../../../core/@types';

@Component({
  selector: 'app-piva-get-data-form',
  templateUrl: './piva-get-data-form.component.html',
  styleUrls: ['./piva-get-data-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PivaGetDataFormComponent implements OnInit {

  @Output() nextStep: EventEmitter<CartData> = new EventEmitter<CartData>();

  PivaForm: FormGroup = this.fb.group({
    PartitaIva: ['', [Validators.required, this.pivaValidator.partitaIvaValidator(), Validators.minLength(11)]]
  });

  loading = false;

  constructor(
    private fb: FormBuilder,
    private pivaValidator: PartitaIvaValidator,
    // private crediSafeService: CreditSafeService,
    private changeDetectionRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  get PIvaCtrl(): AbstractControl {
    return this.PivaForm.get('PartitaIva');
  }

  get PivaErrorStatus(): boolean {
    return this.PIvaCtrl && this.PIvaCtrl.invalid && this.PIvaCtrl.touched;
  }

  get PivaValidStatus(): boolean {
    return this.PIvaCtrl && this.PIvaCtrl.valid && this.PIvaCtrl.touched;
  }

  onSubmit() {

    if (!this.PivaForm.valid) {
      this.PIvaCtrl.markAsTouched();
      return;
    }

    this.loading = true;
    this.changeDetectionRef.detectChanges();

    /*this.crediSafeService.getDataByPiva(this.PIvaCtrl.value).pipe(
      take(1),
      finalize(() => {
        this.loading = false;
      })
    ).subscribe((cartData) => {

      if (cartData && !cartData.PartitaIva){
        cartData = {...cartData, PartitaIva: this.PIvaCtrl.value};
      }

      this.nextStep.emit(cartData);
    });*/

  }
}
