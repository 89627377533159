import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import {Unsubscriber} from './shared/@types';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends Unsubscriber implements OnInit {
  title = 'Olearia Genobile Cart';
  showSiteStructure = false;
  fullWidth = false;
  appVersion = environment.appVersion;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    super();
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.unsubscriber$)).subscribe(val => {
      if (val instanceof RoutesRecognized) {
        const queryParams = val.state.root.firstChild.queryParams;
        if (queryParams && queryParams.showbanners){
          this.showSiteStructure = true;
        }
        if (queryParams && queryParams.fullwidth){
          this.fullWidth = true;
        }
      }
    });
  }

}
