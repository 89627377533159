import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ShopComponent} from './views/shop/shop.component';
import {HomeComponent} from './views/home/home.component';
import {ShopGuardService} from './views/guards/shop.guard';
import {ShopViewResolver} from './views/resolvers/shop.resolver';

const routes: Routes = [
  {
    path: 'shop/:key',
    component: ShopComponent,
    canActivate: [ShopGuardService],
    resolve: [ShopViewResolver]
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
