import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CartData, Ordine} from '../../../../core/@types';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-cart[cartData]',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartComponent {

  @Input() class: string;
  @Input() cartData: CartData;
  @Input() ordine: Ordine;
  @Input() showRiepilogo = false;

  baseImagePath: string = environment.prodottiImageBaseUrl;

  constructor() {
  }

}
