import {
  OnDestroy,
} from '@angular/core';
import {Subject} from 'rxjs';

/** Unsubscriber class that usubscribes all his subscriptions on destroy */
export class Unsubscriber implements OnDestroy {
  protected readonly unsubscriber$: Subject<void> = new Subject();

  constructor() {
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
