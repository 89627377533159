import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

export class ApiAbstract {

  http: HttpClient;
  url: string;
  baseApiVersion: string;

  constructor(httpClient, baseUrl: string, apiVersion?: string) {
    this.http = httpClient;
    this.url = baseUrl;
    this.baseApiVersion = apiVersion;
  }

  protected get(endpoint: string, params: any = {}, options?) {
    return this.http.get(this.getCompleteUrl(endpoint), this.getOptions(params, options));
  }

  protected getById(endpoint: string, id: number | string, options?): Observable<any> {
    return this.http.get(this.getCompleteUrl(endpoint) + '/' + id, options);
  }

  protected post(endpoint: string, item: any, options?) {
    return this.http.post(this.getCompleteUrl(endpoint), item, options);
  }

  protected put(endpoint: string, id: number | string, item: any, options?) {
    return this.http.put(this.getCompleteUrl(endpoint) + '/' + id, item, options);
  }

  protected patch(endpoint: string, id: number | string, item: any, options?) {
    return this.http.patch(this.getCompleteUrl(endpoint) + '/' + id, item, options);
  }

  protected delete(endpoint: string, id: number | string, options?) {
    return this.http.delete(this.getCompleteUrl(endpoint) + '/' + id, options);
  }

  // --------------------------------------------------------------------------------------------------------------------------

  private getOptions(params?: any, options?: any) {

    if (!options) {
      options = {};
    }

    if (params) {
      options.params = new HttpParams({
        fromObject: params
      });
    }

    return options;
  }

  private getApiString() {
    if (this.baseApiVersion) {
      return `${this.url}/${this.baseApiVersion}`;
    } else {
      return this.url;
    }
  }

  private getCompleteUrl(endpoint: string) {
    return this.getApiString() + endpoint;
  }

}
