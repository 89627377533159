import {Directive, ElementRef, HostListener, Input, Self} from '@angular/core';

@Directive({
  selector: 'input[appMaxLenghtTyping]'
})
export class AppMaxLenghtTypingDirective {

  @Input() appMaxLenghtTyping: number;

  constructor(@Self() private elementRef: ElementRef) {
  }

  @HostListener('keypress', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    const input = this.elementRef.nativeElement as HTMLInputElement;
    const selectionLenght = input.selectionEnd - input.selectionStart;
    const keyCode = !event.keyCode ? event.which : event.keyCode;
    if (input.value.length >= this.appMaxLenghtTyping
      && [8, 9, 46, 37, 39].indexOf(keyCode) < 0
      && selectionLenght !== input.value.length) {
      event.preventDefault();
    }
  }

  @HostListener('focus', ['$event'])
  handleFocus(event: FocusEvent) {
    const input = this.elementRef.nativeElement as HTMLInputElement;
    if (input.value.length > this.appMaxLenghtTyping){
      input.select();
    }
  }

}
