<header *ngIf="showSiteStructure">
  <div class="row justify-content-center">
    <div class="col-3 shrink">
      <img alt="logo" src="assets/img/logo.png" />
    </div>
  </div>
</header>

<main [ngClass]="{'container': !fullWidth}">
  <router-outlet></router-outlet>
</main>

<footer class="my-5 text-muted text-center text-small" *ngIf="showSiteStructure">
  <p class="mb-1">&copy; 2022 Olearia Genobile - <small>v. {{appVersion}}</small></p>
</footer>
