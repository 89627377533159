import {CartService} from '../../core/services/cart.service';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {ShopResolveData} from '../../core/@types';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';

const VALID_FORM_FIELDS = [
  'Denominazione',
  'Nome',
  'Cognome',
  'Indirizzo',
  'Cap',
  'Comune',
  'Provincia',
  'PartitaIva',
  'CodiceFiscale',
  'Email',
  'PecCodSdi',
  'Telefono',
  'Quantita'
];

@Injectable()
export class ShopViewResolver implements Resolve<ShopResolveData> {
  constructor(private cartService: CartService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot) {

    // carico la stringa che contine l'identificctivo del carrello sul db
    const ccode = route.params.key;

    // Cerco eventuali paramentri per la compilazione automatica del form
    const setFormData = route.queryParams.setFormData === '1' ?? false;
    const pivaCheck = (route.queryParams.pivaCheck && route.queryParams.pivaCheck === '1') ?? true;
    const formMode = route.queryParams.formMode ?? 'PersonaFisica';
    const Fattura = formMode === 'Azienda';
    // this.cartService.setCartData({IvaInclusa: environment.prezzi_ivati, Fattura});

    // Controllo che il carrello sia esistente
    return this.cartService.loadCartData$(ccode).pipe(
      tap(res => res),
      map(res => res),
      filter((cart) => {
        if(cart.IsOrdine){
          this.router.navigateByUrl('home');
          return false;
        }
        else { return true; }
      }),
      switchMap(() => {
        return of({
          formMode,
          pivaCheck: !!pivaCheck,
          setFormData: !!setFormData
        });
      })
    );

    /*if (setFormData) {
      let queryparams = Object.keys(route.queryParams)
        .filter((k) => VALID_FORM_FIELDS.includes(k))
        .reduce((params, k) => {
          params[k] = route.queryParams[k];
          return params;
        }, {} as any);

      if (formMode === 'PersonaFisica') {
        let Denominazione = '';
        if (queryparams.Nome && queryparams.Cognome) {
          Denominazione = `${queryparams.Nome} ${queryparams.Cognome}`;
        }

        queryparams = {
          ...queryparams,
          Denominazione
        };
      }

      // Imposto i dati nel carrello
      this.cartService.setCartData({...queryparams});
    }

    return {
      formMode,
      pivaCheck: !!pivaCheck,
      setFormData: !!setFormData
    } as ShopResolveData;*/
  }
}
