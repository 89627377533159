import {Component, OnInit, ViewChild} from '@angular/core';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {finalize, take} from 'rxjs/operators';
import {CartService} from '../../core/services/cart.service';
import {CartData, Ordine} from '../../core/@types';
import {BillingFormComponent} from './components/billing-form/billing-form.component';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OrdiniService} from '../../core/services/ordini.service';
import {Observable} from 'rxjs';
import {CompletePay} from '../../shared/@types/_interfaces';
import {PivaGetDataFormComponent} from './components/piva-get-data-form/piva-get-data-form.component';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class ShopComponent implements OnInit {

  @ViewChild(PivaGetDataFormComponent) pivaFormComponent: PivaGetDataFormComponent;
  @ViewChild(BillingFormComponent) billingFormComponent: BillingFormComponent;
  @ViewChild(MatHorizontalStepper) private stepper: MatHorizontalStepper;

  ordine$: Observable<Ordine>;
  cartData$: Observable<CartData>;

  step1Completed = false;
  step2Completed = false;
  loading = false;
  formIsValid = false;
  pivaFormStatus: string | boolean = true;
  showErrorTooltip = false;

  // Parametri di configurazione
  pivaCheck = false;
  formMode: 'Azienda' | 'PersonaFisica' = 'PersonaFisica';

  constructor(public cartService: CartService,
              public ordiniService: OrdiniService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar) {
    this.ordine$ = this.ordiniService.getOrdineAsObservable$();
    this.cartData$ = this.cartService.getCartDataObservable$();
  }

  ngOnInit() {
    const {formMode, pivaCheck} = this.route.snapshot.data[0];

    this.formMode = formMode;
    // this.pivaCheck = pivaCheck;
    if (formMode === 'PersonaFisica') {
      this.pivaCheck = false;
      this.pivaFormStatus = true;
    }
  }

  completeStep1() {

    /*if (this.pivaCheck) {
      if (this.pivaFormComponent && this.pivaFormComponent.PivaForm.valid && !this.pivaFormStatus) {
        this.pivaFormComponent.onSubmit();
        return;
      }

      if (!this.pivaFormStatus) {
        return;
      }
    }*/

    this.step1Completed = true;
    setTimeout(() => this.stepper.next());
  }

  getPivaData(cartData: CartData) {
    this.cartService.setCartData(cartData);

    // controllo partita iva rivenditore
    this.pivaFormStatus = cartData.RagioneSociale ? 'checked' : 'notFound';

    // blocco il campo piva
    setTimeout(() => this.billingFormComponent.formBilling.get('PartitaIva').disable({emitEvent: false}));
  }

  submitBillingForm() {

    if (this.loading) {
      return;
    }

    if (this.step2Completed) {
      this.stepper.next();
      return;
    }

    if (!this.billingFormComponent.formBilling.valid) {
      this.billingFormComponent.markAllControlsTouched();
      this.showErrorTooltip = true;
      return;
    }

    // Commentato perchè ho inserito il getRawValue() sul form
    /*if (this.formMode === 'Azienda') {
      // Riabilito il campo piva per recuperare il dato che altrimenti non verrebbe recuperato
      this.billingFormComponent.formBilling.get('PartitaIva').enable({emitEvent: false});
    }*/

    const cartData = this.cartService.getCartData();
    const ordine = {
      // Dati dal form
      ...this.billingFormComponent.formBilling.getRawValue(),
      // Dati dal carrello
      Imponibile: cartData.Imponibile,
      Iva: cartData.Iva,
      Imposta: cartData.Imposta,
      Totale: cartData.Totale,
      Prodotti: cartData.Prodotti,
      IdCarrello: cartData.id,
      // Parametro aggiuntivo che non viene memorizzato nel database di ricezione ordini
      TipoAzienda: this.formMode
    };

    this.loading = true;
    this.ordiniService.sendOrdine(ordine).pipe(
      take(1),
      finalize(() => this.loading = false),
    ).subscribe(() => {
      this.step2Completed = true;
      this.showErrorTooltip = false;
      this.billingFormComponent.disableCompletedForm();
      setTimeout(() => this.stepper.next());
    });
  }

  formInvalidChange($event: boolean) {
    if ($event && this.showErrorTooltip) {
      this.showErrorTooltip = false;
    }
    this.formIsValid = $event;
  }

  paymentCompleted($event: CompletePay) {
    this.ordiniService.setOrdinePagato($event).pipe(
      take(1)
    ).subscribe(() => {

    });
  }
}
